import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledTextArea,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
  ErrorMessage,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
// import addToMailchimp from 'gatsby-plugin-mailchimp'

const ContactBookingForm = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const [isHowHeardOther, setIsHowHeardOther] = useState(false);
  console.log('isFormValid', isFormValid)
  const interests = [
    // 07010ff6a3
    // curl -H "Authorization: apikey cedd0ed6f90a7c8205e334f8a9218411-us1" https://us1.api.mailchimp.com/3.0/lists/07010ff6a3/interest-categories/2e1178ad10/interests
    // 2e1178ad10 - Audio Purchase 
    {
      "id": "f52fdfb2ed",
      "name": "Balancing Structure"
    },
    {
      "id": "7aa6644a51",
      "name": "Quantum-Touch Morning Meditation"
    },
    {
      "id": "d6a7ff2064",
      "name": "Quantum-Touch Chakra Balance"
    },
    {
      "id": "65eaafac74",
      "name": "Quantum-Touch Brain Healing"
    },
    {
      "id": "d950a0b77f",
      "name": "The Essential Mindfulness Collection"
    },
    {
      "id": "637a687588",
      "name": "Chakra Balancing Collection"
    },
    // Interest ae936beae9
    {
      "id": "cb4f15c6f6",
      "name": "Newsletter Sign Up"
    },
    {
      "id": "a7b7044e05",
      "name": "Contact form"
    },
    // 63a3c2f2bc Events 
    {
      "id": "076cec495e",
      "name": "Quantum-Touch Free Healing Circle"
    }
  ]

  // Validation
  const validate = values => {
    console.log('values', values)
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }
    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }
    if (!values.howHear) {
      errors.howHear = "Required"
    }
    if (values.howHear === 'other') {
      setIsHowHeardOther(true)
      if (!values.howHearOther) {
        errors.howHearOther = "Required"
      }
    } else {
      setIsHowHeardOther(false)
    }
    
    if (!values.telephone) {
      errors.telephone = "Required"
    }

    if (!values.bookingType) {
      errors.bookingType = "Required"
    }
    if (values.bookingType === 'Private Workshop') {
      if (!values.privateWorkshopFor) {
        errors.privateWorkshopFor = "Required"
      }
    }
    if (values.bookingType === 'QT Healing') {
      if (!values.hadQt) {
        errors.hadQt = "Required"
      }
    }

    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      bookingType: "",
      privateWorkshopFor: "",
      hadQt: "",
      usrComment: "",
      howHear: "",
      howHearOther: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        telephone,
        bookingType,
        privateWorkshopFor,
        hadQt,
        usrComment,
        howHear,
        howHearOther,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      // axios.post("/.netlify/functions/formHandler", { firstName, lastName, emailAddress: email.toLowerCase(), usrComment, howHear, interest: interests[7] })
      //   .then(data => {
      //     console.log('ERROR ', data)
      //   })
      //   .catch(console.log)

      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("telephone", telephone)
      bodyFormData.set("bookingType", bookingType)
      bodyFormData.set("privateWorkshopFor", privateWorkshopFor)
      bodyFormData.set("hadQt", hadQt)
      bodyFormData.set("usrComment", usrComment)
      bodyFormData.set("howHear", howHear)
      bodyFormData.set("howHearOther", howHearOther)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/9851/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit} netlify className="preBooking">
      <StyledFieldset>
        <ul>
          <li>
            <StyledFormElementWrapper>
              <StyledLabelHeader
                style={{ 'display': 'block' }}
                required
                className="radiolist"
                isEmpty={errors.bookingType && touched.bookingType}
              >Please select what you would like to make a booking for:</StyledLabelHeader>
              {errors.bookingType && touched.bookingType ? (
                <span className="error-message">{errors.bookingType}</span>
              ) : null}
              <StyledLabel htmlFor="lifeForceEnergy">
                <StyledInput
                  id="lifeForceEnergy"
                  name="bookingType"
                  type="radio"
                  value="Life Force Energy Coaching"
                  checked={values.bookingType === "Life Force Energy Coaching"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Life Force Energy Coaching
                </StyledLabelHeader>

              </StyledLabel>
              {/* <ModalProvider backgroundComponent={FadingBackground}>
                <MoreInfoModal
                  title="Life Force Energy Coaching"
                  moreinfo="<p>Permissioning and TransformationT™ is highly effective at training the body and mind to relax deeply enough that falling and staying asleep becomes easy.</p><p>During the session you will learn how to use the techniques at bedtime. You will also be given access to the Permission to Reset™ Sleep audio program which can be listened to at night.</p><p>The audios are also available to listen to offline.</p>" />
              </ModalProvider> */}
            </StyledFormElementWrapper>
          </li>
          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="qtHealing">
                <StyledInput
                  id="qtHealing"
                  name="bookingType"
                  type="radio"
                  value="QT Healing"
                  checked={values.bookingType === "QT Healing"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Quantum-Touch Energy Healing
                </StyledLabelHeader>
              </StyledLabel>
              {/* <ModalProvider backgroundComponent={FadingBackground}>
                <MoreInfoModal
                  title="Quantum-Touch Energy Healing"
                  moreinfo="<p>Permissioning and TransformationT™ is highly effective at training the body and mind to relax deeply enough that falling and staying asleep becomes easy.</p><p>During the session you will learn how to use the techniques at bedtime. You will also be given access to the Permission to Reset™ Sleep audio program which can be listened to at night.</p><p>The audios are also available to listen to offline.</p>" />
              </ModalProvider> */}
            </StyledFormElementWrapper>
          </li>
          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="permissioningSingle">
                <StyledInput
                  id="permissioningSingle"
                  name="bookingType"
                  type="radio"
                  value="Permissioning Single"
                  checked={values.bookingType === "Permissioning Single"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Permissioning &amp; Transformation™ - 1 bespoke session
                </StyledLabelHeader>
              </StyledLabel>
              {/* <ModalProvider backgroundComponent={FadingBackground}>
                <MoreInfoModal
                  title="Permissioning & Transformation"
                  moreinfo="<p>Permissioning and Transformation™ is highly effective at training the body and mind to relax deeply enough that falling and staying asleep becomes easy.</p><p>During the session you will learn how to use the techniques at bedtime. You will also be given access to the Permission to Reset™ Sleep audio program which can be listened to at night.</p><p>The audios are also available to listen to offline.</p>" />
              </ModalProvider> */}
            </StyledFormElementWrapper>
          </li>

          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="permissioningPackage">
                <StyledInput
                  id="permissioningPackage"
                  name="bookingType"
                  type="radio"
                  value="Permissioning Package"
                  checked={values.bookingType === "Permissioning Package"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Permission to Reset™ Anxiety - 6 sessions
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>

          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="SCH">
                <StyledInput
                  id="SCH"
                  name="bookingType"
                  type="radio"
                  value="Self Created Health"
                  checked={values.bookingType === "Self Created Health"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Self Created Health - 3 sessions
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>

          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="privateWorkshops">
                <StyledInput
                  id="privateWorkshops"
                  name="bookingType"
                  type="radio"
                  value="Private Workshop"
                  checked={values.bookingType === "Private Workshop"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Private workshop
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>
        </ul>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="firstName">
            <StyledLabelHeader
              required
              isEmpty={errors.firstName && touched.firstName}
            >
              First Name
            </StyledLabelHeader>
            <StyledInput
              id="firstName"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.firstName && touched.firstName ? (
            <span className="error-message">{errors.firstName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="lastName">
            <StyledLabelHeader
              required
              isEmpty={errors.lastName && touched.lastName}
            >
              Last Name
            </StyledLabelHeader>
            <StyledInput
              id="lastName"
              name="lastName"
              type="text"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.lastName && touched.lastName ? (
            <span className="error-message">{errors.lastName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="email">
            <StyledLabelHeader
              required
              isEmpty={errors.email && touched.email}
            >
              Email Address
            </StyledLabelHeader>
            <StyledInput
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur("email")}
              required
            />
          </StyledLabel>
          {errors.email && touched.email ? (
            <span className="error-message">{errors.email}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="telephone">
            <StyledLabelHeader
              required
              isEmpty={errors.telephone && touched.telephone}
            >
              Phone Number</StyledLabelHeader>

            <StyledInput
              id="telephone"
              name="telephone"
              type="tel"
              onChange={handleChange}
              value={values.telephone}
              placeholder="+44 (0)7400 123456"
              onBlur={handleBlur("telephone")}
              required
            />
          </StyledLabel>
          {errors.telephone && touched.telephone ? (
            <span className="error-message">{errors.telephone}</span>
          ) : null}
        </StyledFormElementWrapper>

        <ul style={values.bookingType == 'Private Workshop' ? { 'display': 'block' } : { 'display': 'none' }}>
          <li>
            <StyledFormElementWrapper>
              <StyledLabelHeader
                style={{ 'display': 'block' }}
                required
                className="radiolist"
                isEmpty={errors.privateWorkshopFor && touched.privateWorkshopFor}
              >Is this for an individual or company?</StyledLabelHeader>
              {errors.privateWorkshopFor && touched.privateWorkshopFor ? (
                <span className="error-message">{errors.privateWorkshopFor}</span>
              ) : null}
              <StyledLabel htmlFor="IndividualWorkshop">
                <StyledInput
                  id="IndividualWorkshop"
                  name="privateWorkshopFor"
                  type="radio"
                  value="Individual"
                  checked={values.privateWorkshopFor === "Individual"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Individual
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>
          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="CompanyWorkshop">
                <StyledInput
                  id="CompanyWorkshop"
                  name="privateWorkshopFor"
                  type="radio"
                  value="Company"
                  checked={values.privateWorkshopFor === "Company"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Company
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>
        </ul>

        <ul style={values.bookingType == 'QT Healing' ? { 'display': 'block' } : { 'display': 'none' }}>
          <li>
            <StyledFormElementWrapper>
              <StyledLabelHeader
                style={{ 'display': 'block' }}
                required
                className="radiolist"
                isEmpty={errors.hadQt && touched.hadQt}
              >Have you had a Quantum-Touch healing session before?</StyledLabelHeader>
              {errors.hadQt && touched.hadQt ? (
                <span className="error-message">{errors.hadQt}</span>
              ) : null}
              <StyledLabel htmlFor="qtyes">
                <StyledInput
                  id="qtyes"
                  name="hadQt"
                  type="radio"
                  value="Yes"
                  checked={values.hadQt === "Yes"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  Yes
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>
          <li>
            <StyledFormElementWrapper>
              <StyledLabel htmlFor="qtNo">
                <StyledInput
                  id="qtNo"
                  name="hadQt"
                  type="radio"
                  value="No"
                  checked={values.hadQt === "No"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <StyledLabelHeader className="checkbox">
                  No
                </StyledLabelHeader>
              </StyledLabel>
            </StyledFormElementWrapper>
          </li>
        </ul>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="usrComment">
            <StyledLabelHeader>Any other comments</StyledLabelHeader>
            <StyledTextArea
              id="usrComment"
              name="usrComment"
              type="text"
              onChange={handleChange}
              value={values.usrComment}
            />
          </StyledLabel>
        </StyledFormElementWrapper>

        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="howHear">
              <StyledLabelHeader
                required
                isEmpty={errors.howHear && touched.howHear}
              >How did you hear about Karina?</StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="howHear"
                name="howHear"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <StyledOption value="default" disabled hidden>Please select?</StyledOption>
                <StyledOption value="a friend">From a friend</StyledOption>
                <StyledOption value="Facebook">FaceBook</StyledOption>
                <StyledOption value="Google Search">Google Search</StyledOption>
                <StyledOption value="Google Advert">Google Advert</StyledOption>
                <StyledOption value="LinkedIn">LinkedIn</StyledOption>
                <StyledOption value="HI CHI">HI CHI Health &amp; Wellness</StyledOption>
                <StyledOption value="WDDTY">WDDTY</StyledOption>
                <StyledOption value="MeetUp">MeetUp</StyledOption>
                <StyledOption value="AllEvents">AllEvents</StyledOption>
                <StyledOption value="EventBrite">EventBrite</StyledOption>
                <StyledOption value="FHTJ">FHTJ</StyledOption>
                <StyledOption value="other">Other</StyledOption>
              </StyledSelect>
            </StyledLabel>
            {errors.howHear && touched.howHear ? (
              <span className="error-message">{errors.howHear}</span>
            ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>

        {isHowHeardOther &&
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="howHearOther">
              <StyledLabelHeader
                required
                isEmpty={errors.howHearOther && touched.howHearOther}
              >Please can you let us know how you heard?</StyledLabelHeader>
              <StyledTextArea
                id="howHearOther"
                name="howHearOther"
                type="input"
                placeholder=""
                onChange={handleChange}
                value={values.howHearOther}
                onBlur={handleBlur("howHearOther")}
                required
              />
            </StyledLabel>
          </StyledFormElementWrapper>
        }

      </StyledFieldset>
      <StyledButton
        noMargin={true}
        type="submit"
        value="Send Message"
        disabled={isSubmitting || !isFormValid}
      >
        Submit
      </StyledButton>
      {messageSent && !isSuccessMessage && (
        <ErrorMessage>
          <p>Something went wrong please try again.</p>
        </ErrorMessage>
      )}
    </StyledForm>
  )
}

export default ContactBookingForm