import React, { useEffect, useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import {
  Section
} from "../styledComponents/section"
import ContactForm from "../components/formContact"
import { ContactSection, ContactSectionHeading, ContactSectionLedeDiv } from "../styledComponents/contact"
import SEO from "../components/seo"
import ContactBookingForm from "../components/formContactBooking"
import LayoutNoStripe from "../components/layoutNoStripe"

const Heading = ({ level = 1, location, children }) => (
  <ContactSectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </ContactSectionHeading>
)

const Contact = ({ data, location }) => {

  const [tabIndex, setTabIndex] = useState(0);

  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if(urlParams.has("booking")){
      setTabIndex(1)
    }
  }

  useEffect(() => {
    checkIfHasQuery()
  }, [])
  

  return (
    <>
      <LayoutNoStripe displayNavBorder location={location}>
        <SEO
          title="Get in contact with Karina Grant."
          description="Get in contact with Karina today to make a booking or if you have an enquiry, please fill in the form."
          url="/contact"
        />
        <Section>
          <Heading level={1}>
            Contact
          </Heading>
          <ContactSectionLedeDiv>
            {/* {parse(data.wpPage.content)} */}
            <p>Please use the form below to make a general enquiry or a booking</p>
          </ContactSectionLedeDiv>
          <ContactSection>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className="react-tabs contact">
              <TabList>
                <Tab>General enquiry</Tab>
                <Tab defaultFocus="true">Make a booking</Tab>
              </TabList>
              <TabPanel>
                <ContactForm />
              </TabPanel>
              <TabPanel>
                {/* <PreBookingForm /> */}
                <ContactBookingForm />
              </TabPanel>
            </Tabs>
          </ContactSection>
        </Section>
      </LayoutNoStripe>
    </>
  )
}

export default Contact
